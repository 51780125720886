import React from 'react'
import LayoutRe from './Layout'
function TeamRe() {
    return (
        <>
            <LayoutRe>
                <div>
                    <div className="breatcome_area d-flex align-items-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="breatcome_title">
                                        <div className="breatcome_title_inner pb-2">
                                            <h2>Our Team</h2>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="team_area bg_color2 pt-80 pb-75">
                        <div className="container">
                            <div className="row">
                                {/* Start Section Tile */}
                                <div className="col-lg-12">
                                    <div className="section_title text_center mb-50 mt-3">

                                        <div className="section_main_title">
                                            <h1>Our Awesome</h1>
                                            <h1>Team</h1>
                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/Mubarik.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Mubarik Ibrahim </h4>
                                            <span>Managing Director</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/Nana Yaa.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Nana Yaa Savage-Mensah</h4>
                                            <span>Reinsurance Manager </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/Joseph.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Joseph Kwegir-Aggrey</h4>
                                            <span>Reinsurance Broker</span>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>


                </div>
            </LayoutRe>
        </>
    )
}

export default TeamRe
