import React from 'react'
import Layout from '../layouts'
function TeamMGT() {
    return (
        <>
            <Layout>
                <div>
                    <div className="breatcome_area d-flex align-items-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="breatcome_title">
                                        <div className="breatcome_title_inner pb-2">
                                            <h2>Our Team</h2>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ============================================================== */}
                    {/* End Techno Breatcome Area */}
                    {/* ============================================================== */}
                    {/*==================================================*/}
                    {/*--- Start Techno About Area ---*/}
                    {/*==================================================*/}
                    <div className="team_member pt-85 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="section_title text_left mb-40 mt-3">
                                        <div className="section_sub_title uppercase mb-3">
                                            <h6>OUR TEAM</h6>
                                        </div>
                                        <div className="section_main_title">
                                            <h1> Our Experienced Team</h1>
                                            <h1>for your Insurance Needs!!</h1>
                                        </div>
                                        <div className="team_member_text pt-3">
                                            <p>Our Goal Then and Now Is to Provide Best Intermediary Services For Insurance Policies</p>
                                        </div>
                                    </div>
                                    <div className="prfs_experience">
                                        <div className="headingh_experience_number">
                                            <h1>8</h1>
                                        </div>
                                        <div className="heading_experience_title">
                                            <h2>Years of experience in Insurance Brokerage</h2>
                                        </div>
                                    </div>
                                    <div className="team_member_button pt-5">
                                        <div className="button">
                                            <a href="#">Team Member<i className="fa fa-long-arrow-right" /></a>
                                            <a className="active" href="#">Join Our Team<i className="fa fa-long-arrow-right" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="team_join_thumb">
                                        <img class='teamGroup' src="assets/images/team/group.jpg" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno About Area ---*/}
                    {/*==================================================*/}
                    <div className="team_area bg_color2 pt-80 pb-75">
                        <div className="container">
                            <div className="row">
                                {/* Start Section Tile */}
                                <div className="col-lg-12">
                                    <div className="section_title text_center mb-50 mt-3">

                                        <div className="section_main_title">
                                            <h1>Our Awesome</h1>
                                            <h1>Team</h1>
                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/sheila.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Sheila Naa Dodua Wristberg</h4>
                                            <span>Managing Director</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/elijah.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Elijah Owusu Agyeman</h4>
                                            <span>General Manager</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/duah.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Kwaku Duah</h4>
                                            <span>Finance Manager</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/kamal.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Alhassan Kamal -Deen</h4>
                                            <span>Head of Technical</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/ahunu.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Jacob Ahuno
                                            </h4>
                                            <span>Business Development Manager</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/abass.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Abdallah Abass

                                            </h4>
                                            <span>Business Development Manager

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/esther.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Esther Timinca
                                            </h4>
                                            <span>Accountant
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/araba.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Araba Bondzie

                                            </h4>
                                            <span>Business Development Officer

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_team mb-4">
                                        <div className="single_team_thumb">
                                            <img src="assets/images/team/vanett.png" alt />
                                            <div className="single_team_icon">
                                                <a href><i className="fa fa-facebook" /></a>
                                                <a href><i className="fa fa-twitter" /></a>
                                                <a href><i className="fa fa-linkedin" /></a>
                                                <a href><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </div>
                                        <div className="single_team_content">
                                            <h4>Vanett Lamptey

                                            </h4>
                                            <span>Business Development Officer

                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </div>

            </Layout>
        </>
    )
}

export default TeamMGT