import React from 'react'

function Slider2() {

    return (
        <>
            {/*==================================================*/}
            {/*--- Start Techno Slider Area ---*/}
            {/*==================================================*/}
            <div className="slider_list owl-carousel">
                <div className="slider_area d-flex align-items-center slider110" id="home">
                    <div className="container">
                        <div className="row">
                            {/*Start Single Portfolio */}
                            <div className="col-lg-12">
                                <div className="single_slider">
                                    <div className="slider_content">
                                        <div className="slider_text">
                                            <div className="slider_text_inner">
                                                <h5>Welcome To The</h5>
                                                <h1>iRisk Management Limited</h1>
                                                <br />
                                                <h5>Where We Give You Best Insurance Intermediary Services</h5>
                                            </div>
                                            <div className="slider_button pt-5 d-flex">
                                                <div className="button">
                                                    <a href="/brokers/contact">Contact Us <i className="fa fa-long-arrow-right" /></a>
                                                    <a className="active" href="/brokers/about">About Us <i className="fa fa-long-arrow-right" /></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider_area d-flex align-items-center slider120" id="home">
                    <div className="container">
                        <div className="row">
                            {/*Start Single Portfolio */}
                            <div className="col-lg-12">
                                <div className="single_slider">
                                    <div className="slider_content">
                                        <div className="slider_text">
                                            <div className="slider_text_inner">
                                                <h5>What We Do</h5>
                                                <h1>Insurance brokerage and Risk Management Consulting</h1>
                                                <br />
                                                <h5>Provision of highly competitive prices and the timely
                                                    delivery of services second to none </h5>
                                            </div>
                                            <div className="slider_button pt-5 d-flex">
                                                <div className="button">
                                                    <a href="/brokers/contact">Contact <i className="fa fa-long-arrow-right" /></a>
                                                    <a className="active" href="/brokers/about">About Us<i className="fa fa-long-arrow-right" /></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider_area d-flex align-items-center slider130" id="home">
                    <div className="container">
                        <div className="row">
                            {/*Start Single Portfolio */}
                            <div className="col-lg-12">
                                <div className="single_slider">
                                    <div className="slider_content">
                                        <div className="slider_text">
                                            <div className="slider_text_inner wow slideInLeft" data-wow-duration="2s" data-wow-delay="0s">

                                                <h1>Broad Vision, Honest Service, Great Value.</h1>
                                                <br />
                                                <h5>We are dedicated to providing insurance and risk management solutions that combine maximum protection with value addition</h5>
                                            </div>
                                            <div className="slider_button pt-5 d-flex">
                                                <div className="button">
                                                    <a href="/brokers/contact">Contact <i className="fa fa-long-arrow-right" /></a>
                                                    <a className="active" href="/brokers/about">About Us<i className="fa fa-long-arrow-right" /></a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*==================================================*/}
            {/*--- End Techno Slider Area ---*/}
            {/*==================================================*/}





        </>
    )
}

export default Slider2