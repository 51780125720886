import React from 'react'
import Layout from '../layouts'
function ContactMGT() {
    return (
        <>
            <Layout>
                <div>

                    <div className="slider_area appointment d-flex align-items-center slider3" id="home">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="single_slider">
                                        <div className="slider_content">
                                            <div className="slider_text">
                                                <div className="slider_text_inner">
                                                    <h5>iRisk Management</h5>
                                                    <h1>Get in Touch</h1>
                                                    <h1>with Our Team</h1>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="appointment">
                                        <div className="sign-up-form-wrap p-5">
                                            <div className="sign-up-form-header text-center mb-4">
                                                <h4 className="appointment_title mb-2">Make An Appointment</h4>
                                                <p>Get in touch and discover how we can help. We aim to be in touch</p>
                                            </div>
                                            <form action="#" method="post" className="sign-up-form">
                                                <div className="form-group input-group">
                                                    <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="email" name="email" className="form-control" placeholder="Your Email" required />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="text" name="phone" className="form-control" placeholder="Phone Number" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="submit" name="submit" id="submit" className="btn" defaultValue="Send" />
                                                </div>
                                                <div className="form-check d-flex align-items-center text-center" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider_bottom_shape">
                                <div className="slider_bottom_shape_inner">
                                    <img src="assets/images/sld-shap.svg" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Slider Area ---*/}
                    {/*==================================================*/}


                    {/* ============================================================== */}
                    {/* Start Techno Contact Address Area */}
                    {/* ============================================================== */}
                    <div className="contact_address_area pt-80 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section_title text_center mb-55">
                                        <div className="section_sub_title uppercase mb-3">
                                            <h6>CONTACT US</h6>
                                        </div>
                                        <div className="section_main_title">
                                            <h1>We Are Here For You</h1>
                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_contact_address text_center mb-30">
                                        <div className="contact_address_icon pb-3">
                                            <i className="fa fa-map-o" />
                                        </div>
                                        <div className="contact_address_title pb-2">
                                            <h4>Address</h4>
                                        </div>
                                        <div className="contact_address_text">
                                            <p>17 Boundary Road, East Legon, down Kaiser Kitchen jnt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_contact_address text_center mb-30">
                                        <div className="contact_address_icon pb-3">
                                            <i className="fa fa-clock-o" />
                                        </div>
                                        <div className="contact_address_title pb-2">
                                            <h4>Opening Hours</h4>
                                        </div>
                                        <div className="contact_address_text">
                                            <p>Mon - Thu: 8:00am - 05:00pm</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="single_contact_address text_center mb-30">
                                        <div className="contact_address_icon pb-3">
                                            <i className="fa fa-volume-control-phone" />
                                        </div>
                                        <div className="contact_address_title pb-2">
                                            <h4>Contact Directly</h4>
                                        </div>
                                        <div className="contact_address_text">
                                            <p>info@iriskmanagement.net, <br /> 233 (0) 30 250 0168</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ============================================================== */}
                    {/* End Techno Contact Address Area */}
                    {/* ============================================================== */}
                    {/*==================================================*/}
                    {/*--- Start Techno Contact Area ---*/}
                    {/*==================================================*/}

                    {/*==================================================*/}
                    {/*--- End Techno Contact Area ---*/}
                    {/*==================================================*/}
                    {/*==================================================*/}
                    {/*--- End Techno Map Area ---*/}
                    {/*==================================================*/}

                </div>



            </Layout>
        </>
    )
}

export default ContactMGT