import React from 'react'

function Navbar() {
    return (
        <>


            <div>
                {/*==================================================*/}
                {/*--- Start Techno Main Menu Area ---*/}
                {/*==================================================*/}
                <div id="sticky-header" className="techno_nav_manu d-md-none d-lg-block d-sm-none d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="logo mt-4">
                                    <a className="logo_img" href="/" title="techno">
                                        <img className='logoIMG' src="assets/images/logos/IRISKRE LOGO.png" alt="" />
                                    </a>
                                    <a className="main_sticky" href="/" title="techno">
                                        <img className='logoIMG' src="assets/images/logos/IRISKRE LOGO.png" alt="astute" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <nav className="techno_menu">
                                    <ul className="nav_scroll">
                                        <li><a href="/reinsurance">Home</a>

                                        </li>

                                        <li><a href="/reinsurance/#about">About</a>
                                            <ul className="sub-menu">
                                                <li><a href="/reinsurance/#about">About us</a></li>
                                                <li><a href="/reinsurance/team">Our Team </a></li>

                                            </ul>
                                        </li>
                                        <li><a href="/reinsurance/#services">Services</a>

                                        </li>


                                        <li><a href="/reinsurance/contact">Contact</a>

                                        </li>
                                    </ul>
                                    <div className="donate-btn-header">
                                        <a className="dtbtn" href="/reinsurance/contact">Get A Quote</a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/*--- Techno Mobile Menu Area ---*/}
                <div className="mobile-menu-area d-sm-block d-md-block d-lg-none stickynav">
                    <div className="mobile-menu">
                        <nav className="techno_menu">
                            <ul>
                                <li><a href="/reinsurance">Home</a>

                                </li>
                                <li><a href="/reinsurance/#about">About</a>
                                    <ul className="sub-menu">
                                        <li><a href="/reinsurance/#about">About us</a></li>
                                        <li><a href="/reinsurance/team">Our Team </a></li>

                                    </ul>
                                </li>
                                <li><a href="/reinsurance/#services">Services</a>

                                </li>
                                <li><a href="/reinsurance/contact">Contact</a>

                                </li>


                            </ul>
                        </nav>
                    </div>
                </div>

                {/*==================================================*/}
                {/*--- End Techno Main Menu Area ---*/}
                {/*==================================================*/}
            </div>


        </>

    )


}

export default Navbar