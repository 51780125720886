import React from 'react'
import Layout from '../layouts'
function E404MGT() {
  return (
    <>
      <Layout>
        <div>
          {/* ============================================================== */}
          {/* Start Techno Breatcome Area */}
          {/* ============================================================== */}
          <div className="breatcome_area d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breatcome_title">
                    <div className="breatcome_title_inner pb-2">
                      <h2>404</h2>
                    </div>
                    <div className="breatcome_content">
                      <ul>
                        <li><a href="index.html">Home</a> <i className="fa fa-angle-right" /> <a href="#"> Pages</a> <i className="fa fa-angle-right" /> <span>404</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Techno Breatcome Area */}
          {/* ============================================================== */}
          {/*==================================================*/}
          {/*--- Start Techno Error page Area ---*/}
          {/*==================================================*/}
          <div className="error_page_area">
            <div className="container">
              <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8">
                  <div className="single_error">
                    <div className="single_error_inner">
                      <h1>4<span>0</span>4</h1>
                      <h2>Sorry Page Was Not Found!</h2>
                      <p>

                      </p>
                      <div className="single_error_button">
                        <a href="index.html">Back To Home</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </div>
          {/*==================================================*/}
          {/*--- End Techno Error Page Area ---*/}
          {/*==================================================*/}
        </div>

      </Layout>
    </>
  )
}

export default E404MGT
