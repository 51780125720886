import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'

function index({ children }) {
    return (
        <>
            <Navbar />
            {children}
            <Footer />

        </>
    )
}

export default index