import React from 'react'


function Slider1() {
    return (
        <div>
            <div>
                {/*==================================================*/}
                {/* Start Techno Slider Area */}
                {/*==================================================*/}
                <div className="slider_list owl-carousel  " >
                    <div className="slider_area d-flex align-items-center slider1 " id="home" >
                        <div className="container ">
                            <div className="row">
                                {/*Start Single Portfolio */}

                                <div className="col-lg-12">
                                    <div className="single_slider">
                                        <div className="slider_content">
                                            <div className="slider_text">
                                                <div className="slider_text_inner">
                                                    <h5>iRisk Management Limited</h5>
                                                    <h1>Insurance Broker </h1>

                                                </div>

                                                {/* <div className="slider-video">
                                                    <div className="video-icon">
                                                        <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://youtu.be/BS4TUd7FJSg"><i className="fa fa-play" /></a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider_area d-flex align-items-center slider2">
                        <div className="container">
                            <div className="row">
                                {/*Start Single Portfolio */}
                                <div className="col-lg-12">
                                    <div className="single_slider">
                                        <div className="slider_content">
                                            <div className="slider_text">
                                                <div className="slider_text_inner">

                                                    <h1>iRisk Reinsurance <br /> Brokers</h1>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*==================================================*/}
                {/* End Techno Slider Area */}
                {/*==================================================*/}
                {/*==================================================*/}
                {/* Start Techno Flipbox Top Feature Area */}
                {/*==================================================*/}
                <div className="flipbox_area top_feature">
                    <div className="container">
                        <div className="row justify-content-center nagative_margin ">

                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                <div className="techno_flipbox mb-30">
                                    <div className="techno_flipbox_font">
                                        <a href="/brokers">
                                            <div className="techno_flipbox_inner">
                                                <div className="techno_flipbox_icon">
                                                    <img className='logoIMG' src="/assets/images/Layer 1.png" alt="" />
                                                </div>
                                                <div className="flipbox_title">
                                                    <h3>iRisk  Management Limited</h3>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                    <div className="techno_flipbox_back">
                                        <a href="/brokers">
                                            <div className="techno_flipbox_inner">
                                                <div className="flipbox_title">
                                                    <h3>iRisk Management Ltd.</h3>
                                                </div>
                                                <div className="flipbox_desc">
                                                    <p> Ghana's preferred insurance broker</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                <div className="techno_flipbox mb-30">

                                    <div className="techno_flipbox_font">
                                        <a href="/reinsurance">
                                            <div className="techno_flipbox_inner">
                                                <div className="techno_flipbox_icon">
                                                    <img className='logoIMG' src="/assets/images/logos/IRISKRE LOGO.png" alt="" />
                                                </div>
                                                <div className="flipbox_title">
                                                    <h3>iRisk Reinsurance  Brokers</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="techno_flipbox_back">
                                        <a href="/reinsurance">
                                            <div className="techno_flipbox_inner">
                                                <div className="flipbox_title">
                                                    <h3>iRisk Reinsurance Brokers</h3>
                                                </div>
                                                <div className="flipbox_desc">
                                                    <p> Providing reinsurance solutions that match your needs with the best reinsurance terms at the right price.</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
                {/*==================================================*/}
                {/* End Techno Flipbox Top Feature Area */}
                {/*==================================================*/}
            </div>

        </div>
    )
}

export default Slider1