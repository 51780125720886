import React from 'react'
import Layout from './layouts'
function About() {
    return (
        <>
            <Layout>
                <div>About</div>
            </Layout>
        </>


    )
}

export default About