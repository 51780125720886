
import Scripts from "./components/Scripts";
import Routes from "./Routes";

function App() {
  return (
    <>
      <Scripts />
      <Routes />

    </>
  );
}

export default App;
