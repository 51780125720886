import React from 'react'
import Slider1 from '../components/sliders/Slider1'


function Home() {
    return (
        <>

            <Slider1 />

        </>
    )
}

export default Home