import React from 'react'
import Layout from '../layouts'



function AboutMGT() {
    return (
        <>
            <Layout>

                <div className="slider_area d-flex align-items-center slider3" id="home">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single_slider">
                                    <div className="slider_content">
                                        <div className="slider_text">
                                            <div className="slider_text_inner">
                                                <h1> We Provide The Best </h1>
                                                <h1>Service In The Industry</h1>
                                            </div>
                                            <div className="slider_text_desc pt-4">
                                                <p>The hope and desire for promoting and providing a better understanding of insurance packages have always been so key to our priorities.</p>
                                            </div>
                                            <div className="slider_button pt-5 d-flex">
                                                <div className="button">
                                                    <a href="#">READ MORE <i className="fa fa-long-arrow-right" /></a>
                                                </div>
                                            </div>
                                            <div className="slider-video two">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="single_slider_shape">
                                        <div className="single_slider_shape_image">
                                            <img src="assets/images/about.png" alt="true" />
                                        </div>
                                    </div>
                                    <div className="single_slider_rot">
                                        <div className="single_slider_rot_inner rotateme">
                                            <img src="assets/images/sdt.png" alt="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="about_area pt-100 pb-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                <div className="section_title text_left mb-40 mt-3">
                                    <div className="section_sub_title uppercase mb-3">
                                        <h6>// ABOUT iRisk</h6>
                                    </div>
                                    <div className="section_main_title">
                                        <h1>Our Story</h1>
                                        <h1>&amp; Our <span>Culture</span></h1>
                                    </div>
                                    <div className="em_bar">
                                        <div className="em_bar_bg" />
                                    </div>
                                    <div className="section_content_text pt-4">
                                        <p>We are a team of dedicated professionals with relentless focus to delivering value to our cherished clients in relation to our core mandate of providing insurance brokerage and risk management advisory services.
                                            We are guided by the tenets of professionalism, excellence, integrity, and customer centricity in our engagements with all stakeholders.
                                            We are licensed by the insurance industry regulator, the National Insurance Commission (‘’NIC’’).
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                <div className="single_about_thumb mb-3">
                                    <div className="single_about_thumb_inner">
                                        <img src="assets/images/about-4.png" alt="true" />
                                    </div>
                                    <div className="border_ift" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="process_area pt-80 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className=" section_title  mb-55">
                                    <div className="section_sub_title uppercase mb-3">
                                        <h6>iRisk</h6>
                                    </div>
                                    <div className="section_main_title">
                                        <h1>Why Choose Us</h1>
                                    </div>
                                    <div className="em_bar">
                                        <div className="em_bar_bg" />
                                    </div>
                                    <div className='row d-flex'>
                                        <div className=" col-lg-6 col-md-6 col-sm-12 section_content_text pt-4">
                                            <p>Our team of professionals offer customized and specialist insurance solutions at no cost to our cherished clients for maximum value.

                                            </p>
                                            <p> As part of our quality management processes, we also offer free risk assessment services to both corporate and individual clients to identity their risk exposures and recommend appropriate risk improvement measures.</p>
                                            <p>In addition, we provide free training sessions for staff of our clients to ensure they have a detailed understanding of the relevant insurance policies pertaining to their needs.</p>


                                        </div>
                                        <div className=" col-lg-6 col-md-6 col-sm-12 section_content_text pt-4">
                                            <p>We also advise on regulation and compliance to ensure our clients and all relevant stakeholders observe required legislations relating to their sectors or operational area.</p>
                                            <p>We engage with insurers on behalf of clients in connection with the facilitation, negotiation and settlement of claims to meet the expectations of our clients.</p>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                                    <div className="service_style_seven_icon mr-3">
                                        <i className="fa fa-share-square-o" />
                                    </div>
                                    <div className="service_style_seven_content ">
                                        <div className="service_style_seven_title pb-3">
                                            <h4>We are the best</h4>
                                        </div>
                                        <div className="service_style_seven_text">
                                            <p>Provision of highly competitive prices and the timely delivery of services second to none
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                                    <div className="service_style_seven_icon mr-3">
                                        <i className="fa fa-share-square-o" />
                                    </div>
                                    <div className="service_style_seven_content ">
                                        <div className="service_style_seven_title pb-3">
                                            <h4>Sure to recieve professional service</h4>
                                        </div>
                                        <div className="service_style_seven_text">
                                            <p> Going above and beyond for the client whiles staying with the remit of insurance act and the NIC directives.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                                    <div className="service_style_seven_icon mr-3">
                                        <i className="fa fa-circle-o-notch" />
                                    </div>
                                    <div className="service_style_seven_content ">
                                        <div className="service_style_seven_title pb-3">
                                            <h4> We offer quality services
                                            </h4>
                                        </div>
                                        <div className="service_style_seven_text">
                                            <p>the focus of service should be on protecting the interst of the client at all times throught the provision of best policies within the Ghanaian market
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                                    <div className="service_style_seven_icon mr-3">
                                        <i className="flaticon-chart" />
                                    </div>
                                    <div className="service_style_seven_content ">
                                        <div className="service_style_seven_title pb-3">
                                            <h4>Business Reform</h4>
                                        </div>
                                        <div className="service_style_seven_text">
                                            <p>There are many variations of passages of Lorem Ipsum an available, but the majority have suffered alt= "true"eration in thes some form, by injected humour, or randomised</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                                    <div className="service_style_seven_icon mr-3">
                                        <i className="fa fa-share-square-o" />
                                    </div>
                                    <div className="service_style_seven_content ">
                                        <div className="service_style_seven_title pb-3">
                                            <h4>Customer Focused
                                            </h4>
                                        </div>
                                        <div className="service_style_seven_text">
                                            <p>Customer satisfaction is our hallmark</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flipbox_area choose_us pt-85 pb-70" style={{ backgroundImage: 'url(assets/images/slider/slider-4.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section_title text_center white mb-55">

                                    <div className="section_main_title">
                                        <h1>Our Mission, Our Vision <br /> and Our Core Values</h1>
                                    </div>
                                    <div className="em_bar">
                                        <div className="em_bar_bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
                                <div className="techno_flipbox mb-30">
                                    <div className="techno_flipbox_font" style={{ backgroundImage: 'url(assets/images/galery/wh2.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-chart" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Mission</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="techno_flipbox_back" style={{ backgroundImage: 'url(assets/images/galery/wh2.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-chart" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Mission</h3>
                                            </div>
                                            <div className="flipbox_desc">
                                                <p>"To establish successful and lasting partnerships with our clients, staff and the insurance industry, respecting the interests and goals of each stakeholder.
                                                    We are dedicated to providing insurance and risk management  solutions that combine maximum protection with value addition"
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
                                <div className="techno_flipbox mb-30">
                                    <div className="techno_flipbox_font" style={{ backgroundImage: 'url(assets/images/galery/wh1.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-analysis" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Vision</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="techno_flipbox_back" style={{ backgroundImage: 'url(assets/images/galery/wh1.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-analysis" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Vision</h3>
                                            </div>
                                            <div className="flipbox_desc">
                                                <p>iRisk Management will grow to be Ghana's number one insurance broker service provider in the delivery  of quality insurance service anchored on professionalism, quality,  timely delivery of service and premium management, claims handling and risk management to our valued customers.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
                                <div className="techno_flipbox mb-30">
                                    <div className="techno_flipbox_font" style={{ backgroundImage: 'url(assets/images/galery/wh3.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-global-1" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Core Values</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="techno_flipbox_back" style={{ backgroundImage: 'url(assets/images/galery/wh3.jpg)' }}>
                                        <div className="techno_flipbox_inner">
                                            <div className="techno_flipbox_icon mb-2">
                                                <div className="icon">
                                                    <i className="flaticon-global-1" />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>Our Core Values</h3>
                                            </div>
                                            <div className="flipbox_desc">
                                                <ul>
                                                    <li>Constant Improvement
                                                    </li>
                                                    <li>Integrity
                                                    </li>
                                                    <li>Unique Culture
                                                    </li>
                                                    <li>Customer Focus
                                                    </li>
                                                    <li>Value People
                                                    </li>
                                                    <li>Passion for Winning
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="flipbox_button">
                                                <a href>Read More<i className="fa fa-angle-double-right" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-5 align-items-center'>
                    <div className=" col d-flex justify-content-center section_main_title">
                        <h1>Our Core Values</h1>
                    </div></div>

                <div className="row m-5">

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4>Constant Improvement</h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>It is in our DNA to improve at all material times </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4>Integrity</h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>We make conscious efforts to demonstrate honesty and fairness in all our actions
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4> Unique Culture
                                    </h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>We hold ourselves and each other accounatble, work well in teams </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4>Customer Focus</h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>Customer satisfaction is the ultimate </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4>Value People
                                    </h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>We treat all people with dignity and respect</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="service_style_seven pt-3 pb-3 pl-4 pr-3 mb-30">
                            <div className="service_style_seven_icon mr-3">
                                <i className="fa fa-shield" aria-hidden="true"></i>
                            </div>
                            <div className="service_style_seven_content ">
                                <div className="service_style_seven_title pb-3">
                                    <h4>Passion for Winning
                                    </h4>
                                </div>
                                <div className="service_style_seven_text">
                                    <p>We have an unyielding drive for winning</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>








            </Layout>

        </>
    )
}

export default AboutMGT