import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import React from 'react'
import About from '../Pages/About'
import Home from '../Pages/Home'
import HomeMGT from '../Pages/iRisk MGT/HomeMGT'
import AboutMGT from '../Pages/iRisk MGT/AboutMGT'
import ServicesMGT from '../Pages/iRisk MGT/ServicesMGT'
import TeamMGT from '../Pages/iRisk MGT/TeamMGT'
import ContactMGT from '../Pages/iRisk MGT/ContactMGT'
import E404MGT from '../Pages/iRisk MGT/E404MGT'
import HomeRE from '../Pages/iRisk RE/HomeRE'
import ContactRe from '../Pages/iRisk RE/ContactRe'
import TeamRe from '../Pages/iRisk RE/TeamRe'


function index() {
    return (
        <>

            <Router>
                <Routes>

                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />

                    {/* iRisk Management Routes */}
                    <Route path='/brokers' element={<HomeMGT />} />
                    <Route path='/brokers/about' element={<AboutMGT />} />
                    <Route path='/brokers/services' element={<ServicesMGT />} />
                    <Route path='/brokers/team' element={<TeamMGT />} />
                    <Route path='/brokers/contact' element={<ContactMGT />} />




                    {/* iRisk Reinsurance Routes */}
                    <Route path='/reinsurance' element={<HomeRE />} />
                    <Route path='/reinsurance/contact' element={<ContactRe />} />
                    <Route path='/reinsurance/team' element={<TeamRe />} />

                    {/* error pages */}
                    <Route path='*' element={<E404MGT />} />

                </Routes>

            </Router>

        </>
    )
}

export default index
