import React from 'react'
import Slider2 from '../../components/sliders/Slider2'
import Layout from '../layouts'
function HomeMGT() {
    return (
        <>
            <Layout>

                <Slider2 />

                <div className="team_area pt-80 pb-75" style={{ backgroundImage: 'url(assets/images/team-bg2.jpg)' }}>


                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                <div className="section_title text_left mb-40 mt-3">
                                    <div className="section_sub_title uppercase mb-3">
                                        <h6>ABOUT US</h6>
                                    </div>
                                    <div className="section_main_title">
                                        <h1>iRisk Management Limited</h1>

                                    </div>
                                    <div className="em_bar">
                                        <div className="em_bar_bg" />
                                    </div>
                                    <div className="section_content_text bold pt-5">
                                        <p>Where We Give You Best Insurance Intermediary Services</p>
                                    </div>
                                </div>
                                <div className="singel_about_left mb-30">
                                    <div className="singel_about_left_inner mb-3">
                                        <div className="singel-about-content boder pl-4">
                                            <p>iRisk Management is a wholly-owned Ghanaian insurance brokerage and risk management consulting firm incorporated in the year 2014.</p>
                                            <p> With the widespread litigation between insurance beneficiaries and insurance firms, the company was established to foresee several services in insurance with a high sense of professionalism.</p>
                                        </div>
                                    </div>
                                    <div className="singel_about_left_inner pl-4">
                                        <div className="button two">
                                            <a href="/brokers/about">More Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                <div className="single_about_thumb mb-3">
                                    <div className="single_about_thumb_inner">
                                        <img src="assets/images/home1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="service_area pb-100">
                    <div className="container">
                        <div className="row">
                            {/* Start Section Tile */}
                            <div className="col-lg-12">
                                <div className="section_title text_center mb-50 mt-3">
                                    <div className="section_sub_title uppercase mb-3">
                                        <h6>Our Services</h6>
                                    </div>
                                    <div className="section_main_title">
                                        <h1>What We <span>Provide</span></h1>
                                    </div>
                                    <div className="em_bar">
                                        <div className="em_bar_bg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/*Service owl curousel */}
                            <div className="service_list owl-carousel curosel-style">

                                <div className="col-lg-12">
                                    <div className="service_style_12">
                                        <div className="service_style_12_thumb">
                                            <img src="assets/images/new/service1.png" alt="" />
                                        </div>
                                        <div className="service_style12_content">
                                            <div className="service_style_12_icon">
                                                <div className="icon">
                                                    <img className='logoIMG' src="/assets/images/Layer 1.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="service_style_12_content_inner">
                                                <h2>Life insurance</h2>
                                                <p>Life is beautiful but uncertainties are inevitable. Before life uncertainties happen, our Individual and Group Life Insurance teams are here to help you weather the storm through various Life Insurance Products...</p>
                                                <a href="/brokers/services">Read More +</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Start Service Testimonial */}
                                <div className="col-lg-12">
                                    <div className="service_style_12">
                                        <div className="service_style_12_thumb">
                                            <img src="assets/images/new/service3.png" alt="" />
                                        </div>
                                        <div className="service_style12_content">
                                            <div className="service_style_12_icon">
                                                <div className="icon">
                                                    <img className='logoIMG' src="/assets/images/Layer 1.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="service_style_12_content_inner">
                                                <h2>General Insurance</h2>
                                                <p>Your business and personal assets are important to you as they are to us, we will work with you to arrange insurance covers that will provide you with financial protection against losing any of these assets....</p>
                                                <a href="brokers/services">Read More +</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Start Service Testimonial */}
                                <div className="col-lg-12">
                                    <div className="service_style_12">
                                        <div className="service_style_12_thumb">
                                            <img src="assets/images/new/service2.png" alt="" />
                                        </div>
                                        <div className="service_style12_content">
                                            <div className="service_style_12_icon">
                                                <div className="icon">
                                                    <img className='logoIMG' src="/assets/images/Layer 1.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="service_style_12_content_inner">
                                                <h2>Health Insurance</h2>
                                                <p>The psychological and financial burden of your sick employees paying out of pocket for medical bills can be daunting, let us help you arrange a medical insurance program that best suits your needs and budget...</p>
                                                <a href="brokers/services">Read More +</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="service_style_12">
                                        <div className="service_style_12_thumb">
                                            <img src="assets/images/new/service4.png" alt="" />
                                        </div>
                                        <div className="service_style12_content">
                                            <div className="service_style_12_icon">
                                                <div className="icon">
                                                    <img className='logoIMG' src="/assets/images/Layer 1.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="service_style_12_content_inner">
                                                <h2>Risk Advisory Services</h2>
                                                <p>Our qualified Insurance Risk Managers are not only interested in providing insurance for your valuable assets, but will also avail you their expertise in risk control advisory to reduce your exposure to losses as well as liabilities...</p>
                                                <a href="brokers/services">Read More +</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*==================================================*/}
                {/*--- Start Techno Call Do Action Two Area ---*/}
                {/*==================================================*/}
                <div className="call_do_action pt-85 bg_color" style={{ backgroundImage: 'url(assets/images/cn-bg.jpg)' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="call_do_thumb d-flex justify-content-center">
                                    <img src="assets/images/call us.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section_title white text_left mb-60 mt-3">

                                    <div className="section_main_title">
                                        <h1>Need any service ! Just</h1>
                                        <h1>contact us</h1>
                                        <p className="pt-20">We industry expertise to develop solutions that can connect people and businesses</p>
                                    </div>

                                    <div className="button three mt-40">
                                        <a href="/broker/contact">Contact Us<i className="fa fa-long-arrow-right" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*==================================================*/}
                {/*--- End Techno Call Do Action Two Area ---*/}
                {/*==================================================*/}




            </Layout>
        </>
    )
}

export default HomeMGT