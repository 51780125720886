import React from 'react'

function Footer() {
    return (
        <>
            <div className="footer-middle pt-95" style={{ backgroundImage: 'url(assets/images/call-bg.png)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="widget widgets-company-info">
                                <div className="footer-bottom-logo pb-40 ">
                                    <img className='logoIMG' src="assets/images/layer 2.png" alt="" />
                                </div>
                                <div className="company-info-desc">
                                    <p>Where We Give You Best Insurance Intermediary Services.
                                    </p>
                                </div>
                                <div className="follow-company-info pt-3">
                                    <div className="follow-company-text mr-3">
                                        <a href="/brokers/contact"><p>Follow Us</p></a>
                                    </div>
                                    <div className="follow-company-icon">
                                        <a href="https://www.facebook.com/iRiskManagement				
"><i className="fa fa-facebook" /></a>

                                        <a href="https://www.linkedin.com/company/iriskmanagement/?viewAsMember=true				
"><i className="fa fa-linkedin" /></a>
                                        <a href="https://www.instagram.com/iriskmanagement/?hl=en				
"><i className="fa fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="widget widget-nav-menu">
                                <h4 className="widget-title pb-4">Our Services</h4>
                                <div className="menu-quick-link-container ml-4">
                                    <ul id="menu-quick-link" className="menu">
                                        <li><a href="/brokers/services">Life insurance
                                        </a></li>
                                        <li><a href="/brokers/services">General Insurance
                                        </a></li>
                                        <li><a href="/brokers/services">Health Insurance
                                        </a></li>
                                        <li><a href="/brokers/services">Insurance Risk Advisory Services
                                        </a></li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="widget widgets-company-info">
                                <h3 className="widget-title pb-4">Company Address</h3>
                                <div className="company-info-desc">
                                    <p>Location: 17 Boundary Road, East Legon, down Kaiser Kitchen jnt.

                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Postal Address
                                        :</span>Box MD 2671, Madina
                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Phone :</span>+233 (0) 30 250 0168
                                        ,<span></span> <br />+233 (0) 50 169 5383
                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Email :</span> info@iriskmanagement.net
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="widget widgets-company-info">
                                <h3 className="widget-title pb-4">Other Branches
                                </h3>
                                <div className="company-info-desc">
                                    <p>Tamale Branch


                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Location
                                        :</span> Hs. No.115 Tuffour Street, Sabonjida-Tamale, Ghana

                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Phone :</span>050 160 7947
                                    </p>
                                </div>
                                <div className="footer-social-info">
                                    <p><span>Email :</span> info@iriskmanagement.net
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row footer-bottom mt-70 pt-3 pb-1">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-bottom-content">
                                <div className="footer-bottom-content-copy">
                                    <p>© 2022 iRisk Management Ltd ..Rights Reserved. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-bottom-right">
                                <div className="footer-bottom-right-text">
                                    <a className="absod" href="#/">Powered by </a>
                                    <a href="https://datrixtechsolutions.com/"> Datrix Tech Solutions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer