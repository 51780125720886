import React, { Children } from "react";
import FooterRe from "./FooterRe";
import NavbarRe from "./NavbarRe";

function index({ children }) {
    return (
        <>
            <NavbarRe />

            {children}
            <FooterRe />

            
        </>
    )
}

export default index;
