import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import LayoutRe from './Layout'

function HomeRE() {

    const service2 = useRef(null)
    const service1 = useRef(null)

    function scroll(id) {
        return (() => id.current.scrollIntoView()
        )


    }

    return (
        <>
            <LayoutRe>
                <div>
                    {/*==================================================*/}
                    {/*--- Start Techno Slider Area ---*/}
                    {/*==================================================*/}
                    <div className="slider_list owl-carousel">
                        <div className="slider_area d-flex align-items-center slider5" id="home">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="single_slider">
                                            <div className="slider_content color_black">
                                                <div className="slider_text">
                                                    <div className="slider_text_inner">
                                                        <h5 style={{ color: 'white' }}>Welcome To iRisk Reinsurance Brokers</h5>
                                                        <h1> <span>       Speed     Expertise     Consistency.
                                                        </span></h1>
                                                        <h1 style={{ color: 'white' }} >For all that matters to you.</h1>
                                                    </div>

                                                    <div className="slider_button pt-5 d-flex">
                                                        <div className="button">
                                                            <a href="/reinsurance/#about">View Details <i className="fa fa-long-arrow-right" /></a>
                                                            <a className="active" href="/brokers/contact">Contact Us <i className="fa fa-long-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_slider_shape">
                                                <div className="single_slider_shape_image">
                                                    {/* <img src="assets/images/slider-img.png" alt= "true"/> */}
                                                </div>
                                            </div>
                                            <div className="single_slider_rot">
                                                <div className="single_slider_rot_inner rotateme">
                                                    {/* <img src="assets/images/sdt.png" alt= "true"/> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="slider_area d-flex align-items-center slider4" id="home">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="single_slider">
                                            <div className="slider_content color_black">
                                                <div className="slider_text">
                                                    <div className="slider_text_inner">
                                                        <h5>Reinsurance covers</h5>
                                                        <h1> <span>Reinsurance </span></h1>
                                                        <h1>covers</h1>
                                                    </div>
                                                    <div className="slider_text_desc pt-4">
                                                        <p>We do not just place or arrange reinsurance covers with any company but we ensure that such arrangements are made with companies with best financial, technical and solvency conditions.</p>
                                                        <p>"We provide a comprehensive range of facultative and treaty reinsurance solutions whilst ensuring you receive the best terms at the right prices. We do not only aspire to be your preferred broker in arranging your reinsurance solutions, we also support you with the expertise of our technical team as well as that of our global partners.
                                                            "




                                                        </p>
                                                    </div>
                                                    <div className="slider_button pt-5 d-flex">
                                                        <div className="button">
                                                            <a href="/reinsurance/#about">View Details <i className="fa fa-long-arrow-right" /></a>
                                                            <a className="active" href="/brokers/contact">Contact Us <i className="fa fa-long-arrow-right" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_slider_rot">
                                                <div className="single_slider_sp bounce-animate">
                                                    <img src="assets/images/insur.png" alt= "true"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Slider Area ---*/}
                    {/*==================================================*/}



                    {/*==================================================*/}
                    {/*--- Start Techno About Area ---*/}
                    {/*==================================================*/}
                    <br />
                    <div id={'about'} className="about_area pb-100">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="section_title text_left mb-40 mt-3">
                                        <div className="section_sub_title uppercase mb-3">
                                            <h6>//iRisk Reinsurance Brokers</h6>
                                        </div>
                                        <div className="section_main_title">
                                            <h1>About Us</h1>

                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                        <div className="section_content_text pt-4">

                                            <p>
                                                We provide a comprehensive range of facultative and treaty reinsurance solutions whilst ensuring you receive the best terms at the right prices. We do not only aspire to be your preferred broker in arranging your reinsurance solutions, we also support you with the expertise of our technical team as well as that of our global partners.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="single_about_thumb mb-3">
                                        <div className="single_about_thumb_inner">
                                            <img src="assets/images/about1.png" alt="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno About Area ---*/}
                    {/*==================================================*/}
                    {/*==================================================*/}
                    {/*--- Start Techno Feature Area ---*/}
                    {/*==================================================*/}
                    <div id={'services'} className="feature_area bg_color3 pt-100 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section_title text_center mb-55">
                                        <div className="section_sub_title uppercase mb-3">
                                            <h6 >// WHAT WE DO</h6>
                                        </div>
                                        <div className="section_main_title">
                                            <h1>We Provide The Best Service </h1>
                                            <h1>In <span>Reinsurance Broking</span></h1>
                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center">

                                <div onClick={scroll(service1)} className="col-lg-4 col-md-6 col-sm-12" >

                                    <div className="single_feature_seven">
                                        <div className="single_feature_six_seven_icon">
                                            <div className="icon">
                                                <i className="flaticon-data" />
                                            </div>
                                        </div>
                                        <div className="single_feature_seven_content">
                                            <h4>Reinsurance Arrangement
                                            </h4>
                                            <p><ul style={{ listStyleType: 'none' }}>
                                                <li>Facultative Reinsurance

                                                </li>
                                                <li>
                                                    Treaty Reinsurance

                                                </li>
                                                <li>Specialized Programs	</li>
                                            </ul></p>
                                        </div>
                                    </div>

                                </div>

                                <div onClick={scroll(service2)} className="col-lg-4 col-md-6 col-sm-12">

                                    <div className="single_feature_seven">
                                        <div className="single_feature_six_seven_icon">
                                            <div className="icon">
                                                <i className="flaticon-chart" />
                                            </div>
                                        </div>
                                        <div className="single_feature_seven_content">
                                            <h4>Advisory Services
                                            </h4>
                                            <p><ul style={{ listStyleType: 'none' }}>
                                                <li>
                                                    Underwriting Advisory

                                                </li>
                                                <li>Risk Surveys for Large/Complex Risk</li>
                                            </ul></p>
                                        </div>
                                    </div>

                                </div>







                            </div>
                        </div>
                    </div>
                    <div id={'services1'} className="about_area pb-100">
                        <div className="container">
                            <div ref={service1} className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="section_title text_left mb-40 mt-3">

                                        <div className="section_main_title">
                                            <h1>Reinsurance Arrangement
                                            </h1>

                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                        <div className="section_content_text pt-4">
                                            <p>Our team of experts and global reach of partners allow us to arrange all kinds of reinsurance programmes that provides you with the best coverage at competitive prices.	</p>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="single_about_thumb mb-3">
                                        <div className="d-flex justify-content-center" >
                                            <img className='servicesimg' src="assets/images/about11.png" alt="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div ref={service2} className="about_area pb-100">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                                    <div className="section_title text_left mb-40 mt-3">

                                        <div className="section_main_title">
                                            <h1>Advisory Services

                                            </h1>

                                        </div>
                                        <div className="em_bar">
                                            <div className="em_bar_bg" />
                                        </div>
                                        <div className="section_content_text pt-4">
                                            <p>Our team of experts and global partners are available to provide you with the needed advisory in  underwriting of complex risk
                                            </p>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6 ">
                                    <div className="single_about_thumb mb-3">
                                        <div className="d-flex justify-content-center" >
                                            <img className='servicesimg' src="assets/images/about11.png" alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Feature Area ---*/}
                    {/*==================================================*/}

                    {/*==================================================*/}
                    {/*--- Start Techno Contact Area ---*/}
                    {/*==================================================*/}
                    <div className="main_contact_area app pt-80 bg_fixed " style={{ backgroundImage: 'url(assets/images/slider/slider14.jpg)' }} >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="single_contact_abs_thumb d-flex justify-content-center">
                                        <img src="assets/images/Reinsurance/contactRe.png" alt="true" />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="appointment">
                                        <div className="sign-up-form-wrap p-5">
                                            <div className="sign-up-form-header text-center mb-4">
                                                <h4 className="appointment_title mb-2">Make An Appointment</h4>
                                                <p>Get in touch and discover how we can help. We aim to be in touch</p>
                                            </div>
                                            <form action="#" method="post" className="sign-up-form">
                                                <div className="form-group input-group">
                                                    <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="email" name="email" className="form-control" placeholder="Your Email" required />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="text" name="phone" className="form-control" placeholder="Phone Number" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="submit" name="submit" id="submit" className="btn" defaultValue="Send" />
                                                </div>
                                                <div className="form-check d-flex align-items-center text-center" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Contact Area ---*/}
                    {/*==================================================*/}






                </div >

            </LayoutRe >
        </>
    )
}

export default HomeRE
