import React from 'react'
import { Helmet } from 'react-helmet'



function Scripts() {


    return (
        <>
            <Helmet>

                <script type="text/javascript" src="assets/js/vendor/modernizr-3.5.0.min.js"></script>

                <script type="text/javascript" src="assets/js/vendor/jquery-3.2.1.min.js"></script>

                <script type="text/javascript" src="assets/js/bootstrap.min.js"></script>

                <script type="text/javascript" src="assets/js/owl.carousel.min.js"></script>

                <script type="text/javascript" src="assets/js/jquery.counterup.min.js"></script>

                <script type="text/javascript" src="assets/js/waypoints.min.js"></script>

                <script type="text/javascript" src="assets/js/wow.js"></script>

                <script type="text/javascript" src="assets/js/imagesloaded.pkgd.min.js"></script>

                <script type="text/javascript" src="venobox/venobox.js"></script>

                <script type="text/javascript" src="assets/js/ajax-mail.js"></script>

                <script type="text/javascript" src="assets/js/testimonial.js"></script>

                <script type="text/javascript" src="assets/js/animated-text.js"></script>

                <script type="text/javascript" src="venobox/venobox.min.js"></script>

                <script type="text/javascript" src="assets/js/isotope.pkgd.min.js"></script>

                <script type="text/javascript" src="assets/js/jquery.nivo.slider.pack.js"></script>

                <script type="text/javascript" src="assets/js/jquery.meanmenu.js"></script>

                <script type="text/javascript" src="assets/js/jquery.scrollUp.js"></script>

                <script type="text/javascript" src="assets/js/theme.js"></script>





            </Helmet>
        </>
    )
}

export default Scripts