import React from 'react'
import Layout from '../layouts'

function ServicesMGT() {
    return (
        <>
            <Layout>
                <div>
                    {/*==================================================*/}
                    {/*--- Start Techno Slider Area ---*/}
                    {/*==================================================*/}
                    <div className="slider_area d-flex align-items-center slider7" id="home">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single_slider">
                                        <div className="slider_content text_center">
                                            <div className="slider_text">
                                                <div className="slider_text_inner">
                                                    <h5>Our Services</h5>

                                                </div>
                                                <div className="slider_text_desc pt-4">
                                                    <p>We can help you choose the right General, life, Health insurance and Risk Advisory Services


                                                    </p>
                                                </div>
                                                <div className="slider_button pt-5">
                                                    <div className="button">
                                                        <a href="#">Contact Us <i className="fa fa-long-arrow-right" /></a>
                                                        <a className="active" href="#">Get a Quote<i className="fa fa-long-arrow-right" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Slider Area ---*/}
                    {/*==================================================*/}
                    {/*==================================================*/}
                    {/*--- Start Techno Flipbox Top Feature Area ---*/}
                    {/*==================================================*/}
                    <div className="feature_area ">
                        <div >
                            <div className="row mr-20 ml-20 nagative_margin">
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                    <div className="feature_style_four mb-30">
                                        <div className="feature_style_four_icon mb-20">
                                            <div className="icon">
                                                <i className="flaticon-global" />
                                            </div>
                                        </div>
                                        <div className="feature_style_four_title">
                                            <h4>Life insurance 	</h4>
                                        </div>
                                        <div className="feature_style_four_text pt-15">
                                            <p>Life is beautiful but uncertainties are inevitable. Before life uncertainties happen, our Individual and Group Life Insurance teams are here to help you weather the storm through various Life Insurance Products. We can tailor these products to your specific needs.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                    <div className="feature_style_four  mb-30">
                                        <div className="feature_style_four_icon mb-20">
                                            <div className="icon">
                                                <i className="flaticon-global" />
                                            </div>
                                        </div>
                                        <div className="feature_style_four_title">
                                            <h4>General (Non-Life) Insurance</h4>
                                        </div>
                                        <div className="feature_style_four_text pt-15">
                                            <p>Your business and personal assets are important to you as they are to us, we will work with you to arrange insurance covers that will provide you with financial protection against losing any of these assets. We will also assist you arrange financial protection against liabilities that may be brought against you that can affect your business’s success.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                    <div className="feature_style_four mb-30">
                                        <div className="feature_style_four_icon mb-20">
                                            <div className="icon">
                                                <i className="flaticon-global" />
                                            </div>
                                        </div>
                                        <div className="feature_style_four_title">
                                            <h4>Health Insurance	</h4>
                                        </div>
                                        <div className="feature_style_four_text pt-15">
                                            <p>The psychological and financial burden of your sick employees paying out of pocket for medical bills can be daunting, let us help you arrange a medical insurance program that best suits your needs and budget.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
                                    <div className="feature_style_four mb-30">
                                        <div className="feature_style_four_icon mb-20">
                                            <div className="icon">
                                                <i className="flaticon-global" />
                                            </div>
                                        </div>
                                        <div className="feature_style_four_title">
                                            <h4>Risk Advisory Services	</h4>
                                        </div>
                                        <div className="feature_style_four_text pt-15">
                                            <p>Our qualified Insurance Risk Managers are not only interested in providing insurance for your valuable assets, but will also avail you their expertise in risk control advisory to reduce your exposure to losses as well as liabilities.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==================================================*/}
                    {/*--- End Techno Flipbox Top Feature Area ---*/}
                    {/*==================================================*/}
                </div>




            </Layout>
        </>
    )
}

export default ServicesMGT
